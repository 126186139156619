<template>
	<div class="container-cards">
		<div class="card" @click="selectProduct(quoteItem)" v-for="(quoteItem,index) in quoteData">
			<div class="header">

				<div class="selector">
					<input type="radio"  name="selectOption" :id="'product-'+quoteItem.quote.mondoInternalProduct" class="form-radio">

					<label for="radio-one">
						<img v-if="index==0" src="https://mondo-api.com/widgetah/images/default/shield_orange.png" style="position:relative;top:6px;width: 25px;">
						<img v-if="index==1" src="https://mondo-api.com/widgetah/images/default/shield_blue.png" style="position:relative;top:6px;width: 25px;">
						{{quoteItem.productName}} <span class="color-primary">+{{formatTotalPrice(quoteItem.quote.totalPrice)}} &euro;</span>
					</label>

				</div>
			</div>
			<div class="body">
				<div class="coverages">
					<ul>

						<li v-for="(item,key,index) in filterCoverages(quoteItem.coverages)" v-if="item.itemsOptions[0]">{{item.coverageName}}
               <!-- <span class="color-primary">{{formatPrice(item)}}</span> -->
						</li>
						<li class="deleted" v-else>{{item.coverageName}}
						</li>
					</ul>

				</div>
				<div class="details">

				</div>

			</div>

		</div>
		<div class="card " @click="selectProduct(0)">
			<div class="header">
				<div class="selector">
					<input type="radio"  name="selectOption" :id="'product-0'" class="form-radio"><label for="radio-one">
						Sin seguro 
					</label>

				</div>
			</div>
			<div class="body">

			</div>
			<div class="footer">

			</div>

		</div>
	</div>

</template>

<script>
export default {
	name: "cardDesktopProduct",
	props: ['quoteData', 'removeAllProducts'],
	watch: {
		removeAllProducts: function (val) {

			if (val) {
				var ele = document.getElementsByName("selectOption");
				for (var i = 0; i < ele.length; i++)
					ele[i].checked = false;


			}

		},

	},

	methods: {
		filterCoverages(coverages) {
			let newCoverages = []
			coverages.forEach(function (coverage, index) {
				if (index == 1) {
					let itemOption = []
					itemOption.push({
						value: 0
					})
					newCoverages.push({
						coverageId: null,
						coverageName: 'Consulta médica por WhatsApp',
						itemsOptions: itemOption
					})
          newCoverages.push(coverage)
         
				} else {
         
					newCoverages.push(coverage)
				}
			})
      
			return newCoverages
		},
		formatPrice(item,decimals) {
			if (item.itemsOptions[0]) {
				if (item.itemsOptions[0].value != 0) {
					return item.itemsOptions[0].value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })

				}
				return null
			}
			return ''

		},
    formatTotalPrice(item) {
     return parseInt(item)

		},

		selectProduct(product) {
      if(product == 0){
         document.getElementById("product-0").checked = true;
      } else{
        document.getElementById("product-"+product.quote.mondoInternalProduct).checked = true;
      }
     

			this.$emit('selectedProduct', product);
			this.$emit('removeAllProductsEvent', false);

		},
		removeProducts() {

		}
	}
};
</script>

