<template>
  
		<component v-on:selectedTravelData="selectedTravelData" :widgetOptions="widgetOptions" :data="data" :is="initComponentRender"></component>
 
</template>

<script>
export default {
  props:['data','widgetOptions'],
	methods: {
    selectedTravelData(payload){
    
     	this.$emit('emitResponse', payload);
    }
	},
	computed: {

		initComponentRender() {
			if (this.isMobile()) {
				return () => import('./partials/mobile/index');
			}
			return () => import('./partials/desktop/index');

		},
	},
	created() {

	},
};
</script>

