import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedCoverages: [],
    travelData: [],
    currentProduct: [],
    axiosInstance: [],
    pricingData: [],
    loadingPricing:false,
  },
  mutations: {
    setLoadingPricing(state,data){
      this.state.loadingPricing = data
    },
    setPricingData(state, data) {
      this.state.pricingData = data
    },
    setAxiosInstance(state, data) {
      this.state.axiosInstance = data
    },
    setCurrentProduct(state, data) {
      this.state.currentProduct = data
    },
    setTravelData(state, data) {
      this.state.travelData = data
    },
    setCoveragesBySubProduct(state, data) {

      //How this works
      /**
       *  We have a group of coverages (Featured,Optional, Variable and Franchise)
       *  If client select a featured coverage
       */
      if (data) {
        let that = this
        switch (data.type) {
          case 'featured':
            let toDelete = this.state.selectedCoverages.filter(function (e) {
              if (e.type === 'featured') {
                return e.subProductId === data.subProductId
              }
            });
            toDelete.forEach(function (element) {
              var index = that.state.selectedCoverages.indexOf(element)
              that.state.selectedCoverages.splice(index, 1)
            })


            data.coverages.forEach(function (coverage) {
              that.state.selectedCoverages.push({
                subProductId: data.subProductId,
                coverages: coverage.value,
                type: data.type
              }
              )
            })

            break;

          case 'optionalMultiple':
            let toDeleteOptionalMultiple = this.state.coveragesBySubProduct.filter(function (e) {
              if (e.type === 'optionalMultiple') {
                return e.subProductId === data.subProductId
              }
            });
            toDeleteOptionalMultiple.forEach(function (element) {
              var index = that.state.coveragesBySubProduct.indexOf(element)
              that.state.coveragesBySubProduct.splice(index, 1)
            })
            if (data.action === 'add') {
              this.state.coveragesBySubProduct.push({
                subProductId: data.subProductId,
                coverages: data.coverages,
                type: data.type
              }
              )
            }

            break;
          case 'optionalCheckbox':

            let optionalCheckbox = this.state.selectedCoverages.filter(function (e) {
              if (e.type === 'optionalCheckbox') {
                return e.subProductId === data.subProductId
              }
            });
            optionalCheckbox.forEach(function (element) {
              var index = that.state.selectedCoverages.indexOf(element)
              that.state.selectedCoverages.splice(index, 1)
            })

            this.state.selectedCoverages.push({
              subProductId: data.subProductId,
              coverages: data.coverages,
              type: data.type
            }
            )

            break;
          case 'franchise':
            let toDeleteFranchise = this.state.selectedCoverages.filter(function (e) {
              if (e.type === 'franchise') {
                return e.subProductId === data.subProductId
              }
            });
            toDeleteFranchise.forEach(function (element) {
              var index = that.state.selectedCoverages.indexOf(element)
              that.state.selectedCoverages.splice(index, 1)
            })
            if (data.action === 'add') {
              this.state.selectedCoverages.push({
                subProductId: data.subProductId,
                coverages: data.coverages,
                type: data.type
              }
              )
            }
            break;
        }
      } else {
        this.state.selectedCoverages = []
      }



    }
  },
  actions: {

  }
})
