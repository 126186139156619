<template>
	<div id="mondo-container">
		<div class="title" style="display:none">
			Seguro de viaje: <span class="subTitle">Coberturas por cada viajero</span>
		</div>
		<div class="detailsTitle">

			<a :href="data.budgetFile" target="_blank"><span class="color-primary">Ver más detalles </span></a>
		</div>
		<div class="widget-ah-mobile">
			<product-card v-on:removeAllProductsEvent="removeProductsEvent" :removeAllProducts="removeAllProducts" v-on:selectedProduct="selectedProduct" :quoteData="data.quotes"></product-card>

			<div class="footer">

				<!-- POWERED BY - START -->
				<div class="poweredBy">
					<img src="https://mondo-api.com/widgetah/images/default/powered.svg">
				</div>
				<!-- POWERED BY - END -->
			</div>
		</div>

	</div>
</template>

<script>
import productCard from './card'
import store from '../../../../store'
export default {
	components: {
		productCard
	},
	props: ['data', 'widgetOptions'],
	data() {
		return {
			removeAllProducts: false,
			travelData: {}
		}
	},
	methods: {
		removeProducts() {
			if (!this.removeAllProducts) {
				this.$emit('selectedTravelData', null);
			}
			this.removeAllProducts = !this.removeAllProducts
		},
		formatPrice(item) {
			if (item.itemsOptions[0]) {
				return item.itemsOptions[0].value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })
			}
			return '-'

		},
		buildTooltip(text) {
			if (text) {
				return text.join(' ')

			}
		},
		removeProductsEvent() {
			var ele = document.getElementsByName("checkDismiss");
			for (var i = 0; i < ele.length; i++)
				ele[i].checked = false;
			this.removeAllProducts = false
		},
		extractHeaderCoverages() {
			let coveragesHeader = []
			this.data.forEach(function (data) {
				if (data.coverages) {
					data.coverages.forEach(function (header) {
						coveragesHeader.push(header.coverageName)
					})
				}
			})
			return coveragesHeader
		},


		selectedProduct(payload) {

			if (payload != 0) {
				let travelDataResponse = {
          success:true,
					travelData: store.state.travelData,
					productName: payload.productName,
					mondoInternalProduct: payload.quote.mondoInternalProduct,
					travelers: [],
					quote: {
						totalPrice: payload.quote.totalPrice,
						pricePerPax: payload.quote.totalPricePerPax,
						qtyPax: store.state.pricingData.travelData.startDate,
					}

				}
				this.$emit('selectedTravelData', travelDataResponse);

			} else {
				this.$emit('selectedTravelData', null);
			}



		}
	}
};
</script>
<style   lang="scss">
@import "../../../../assets/scss/_variables.scss";

#mondo-container {
	margin: 20px;
	padding: 0;
	border: 0;
	outline: 0;
	font-family: $fontFamily;
	font-size: 10px;
	vertical-align: baseline;
	line-height: 1;

	@import "../../../../assets/scss/index-mobile.scss";
}
#mondo-container a {
	text-decoration: none !important;
	color: #3b927f !important;
}

#mondo-container a:hover {
	text-decoration: underline !important;
	color: #3b927f !important;
}

#mondo-container .title {
	font-size: 12px;
	vertical-align: baseline;
	line-height: 1;
	padding-bottom: 11px;
	font-weight: 600;
	font-size: 15px;
}

#mondo-container .subTitle {
	font-size: 12px;
	font-weight: normal;
}
#mondo-container .detailsTitle {
	float: right;
	padding-bottom: 10px;
	font-size: 12px;
}
</style>