import Vue from 'vue'


import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element';

import VueSimpleSVG from 'vue-simple-svg'
Vue.use(VueSimpleSVG)
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
Vue.mixin({
  methods: {
    isMobile() {
      let containerWidth = document.getElementById("mondowidget").clientWidth;
      return containerWidth < 684
    },
  }
})



Vue.use(vueCustomElement);
Vue.config.productionTip = false
import InitComponent from './components/init.vue';

Vue.customElement('mondo-widget', InitComponent, {

})/*
new Vue({
  store,
  render: h => h(App)
}).$mount('#mondowidget')
*/