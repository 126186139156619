<template>

	<div id="mondowidget">
		<component v-on:emitResponse="emitResponse" :widgetOptions="widgetOptions" :data="pricingDataResults" v-if="initComponentRender" :is="initComponentRender"></component>

	</div>

</template>

<script>
//import axios from 'axios'
import axios from 'axios'
import store from '../store'
export default {
	components: {
	},

	methods: {
		getWidgetConfiguration() {


			return this.axiosInstance.get('/widget/', {

			})
				.then(response => {
					return response.data.data
				})
				.catch(e => {

				})
		},

		getBusinessUnitToken() {
			return this.apiToken
		},
		emitResponse(payload) {
			this.$emit('responseEvent', payload);
		},
   
		createInstance() {

			this.axiosInstance = axios.create({
				baseURL: process.env.VUE_APP_API_URL,
				headers: {
					'X-PARTNER-BID': this.apiToken,
				}
			})
			store.commit('setAxiosInstance', this.axiosInstance)
		},
		setPolicy(policyData) {

			this.axiosInstance.post('/policy/', {
				startDate: policyData.travelData.startDate,
				endDate: policyData.travelData.endDate,
				paxNumber: policyData.travelData.paxNumber,
				destinationPax: policyData.travelData.destinationPax,
				fromPax: policyData.travelData.originPax,
				mondoInternalProduct: policyData.mondoInternalProduct,
				travelers: policyData.travelers,
			})
				.then(response => {
					this.$emit('responsePolicy', response);

				})
				.catch(e => {


				})
			this.createInstance()
		},
		getQuotes() {
      
			this.createInstance()
			this.getWidgetConfiguration().then(configuration => {

				if (configuration.configuration) {
					this.widgetOptions = JSON.parse(configuration.configuration)
				}
				let travelData = {
					startDate: this.startDate,
					endDate: this.endDate,
					paxNumber: this.paxNumber,
					destinationPax: this.destinationPax,
					originPax: this.originPax
				}
				store.commit('setTravelData', travelData)

				this.axiosInstance.post('/quote/', {
					startDate: this.startDate,
					endDate: this.endDate,
					paxNumber: this.paxNumber,
					destinationPax: this.destinationPax,
					fromPax: this.originPax
				})
					.then(response => {
            
						
						if (!response.data.success) {
							this.pricingErrors = true
							this.pricingErrorMessage = JSON.parse(response.data.message)
							this.$emit('failQuote', {success:false});
             
							return false
						}
						this.pricingErrors = false
						this.pricingErrorMessage = null
						this.pricingResponse = response.data.data
						this.initComponent = this.widgetOptions.componentPath
						store.commit('setPricingData', this.pricingResponse)
					})
					.catch(e => {
							this.$emit('failQuote', {success:false});

					})
			});




		},

	},
	computed: {
		pricingDataResults() {
			return store.state.pricingData
		},
		initComponentRender() {
			if (this.initComponent) {
				return () => import('./default');
			}
			return null

		},
	},
	data() {

		return {
			apiToken: null,
			initComponent: null,
			startDate: null,
			endDate: null,
			travelType: null,
			originPax: null,
			destinationPax: null,
			axiosInstance: null,
			paxNumber: null,
			pricingResponse: [],
			pricingErrors: false,
			pricingErrorMessage: null,
			isLoading: true,
			widgetOptions: {},
		}
	},

	mounted() {

	},

	created() {

	},

}
</script>
